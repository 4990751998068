import React from "react"
import { Helmet } from "react-helmet"
import { graphql } from "gatsby"
import { Box, Button, Grid, GridItem, Text } from "@chakra-ui/react"

import { CategoryBlock } from "../components/Blocks/CategoryBlock"
import { Heading } from "../components/Heading"
import { Player } from "../components/VideoPlayer/Player"
import { LatestNews } from "../components/LatestNews"
import { Container } from "../components/Container"
import { Layout } from "../layouts/default"

import PortableText from "../components/PortableText"
import HeroVideo from "../components/HeroVideo"

const HomeTemplate = ({ data, pageContext }) => {
  const { _rawContent, seo } = data.sanityHomepage
  const { locale } = pageContext

  return (
    <Layout locale={locale}>
      <Helmet>
        <title>Micropore Technologies - Home page</title>
        <meta name="description" content={seo.description} />
        <meta name="keywords" content={seo.keywords} />
      </Helmet>

      {data.sanityHomepage.videoSlides?.length > 0 && (
        <HeroVideo
          slides={data.sanityHomepage.videoSlides}
          duration={data.sanityHomepage.duration}
          locale={locale}
        />
      )}

      <Container bg="white" pt={2} pos="relative" zIndex={10}>
        <Grid
          templateColumns="repeat(12, 1fr)"
          gap={{ base: 0, lg: 12 }}
          pt={12}
          mb={{ base: -16 }}
        >
          <GridItem
            colSpan={{ base: 12, lg: 5 }}
            px={{ base: 6, lg: 0 }}
            textAlign={{ base: "center", lg: "left" }}
            mb={{ base: 12, lg: 0 }}
          >
            {data.sanityHomepage.homepageTitle && (
              <Heading
                as="h2"
                fontSize={{ base: 32, lg: 48 }}
                lineHeight={1}
                color="#878787"
                mb={4}
              >
                {data.sanityHomepage.homepageTitle[locale] ||
                  data.sanityHomepage.homepageTitle.en}
              </Heading>
            )}

            {data.sanityHomepage.homepageVideoCta && (
              <Box mt={{ base: 8, lg: 24 }}>
                <Text
                  fontSize="2xl"
                  fontWeight="600"
                  maxW={{ base: "100%", lg: 500 }}
                  lineHeight={1.2}
                  textColor="black"
                  marginBottom={4}
                >
                  {data.sanityHomepage.homepageVideoCta.videoCtaTitle[locale] ||
                    data.sanityHomepage.homepageVideoCta.videoCtaTitle.en}
                </Text>

                {data.sanityHomepage.homepageVideoCta.videoCtaYouTubeUrl && (
                  <Button
                    as="a"
                    href={
                      data.sanityHomepage.homepageVideoCta.videoCtaYouTubeUrl
                    }
                    target="_blank"
                    rounded="full"
                    bgColor="brand.200"
                    textColor="white"
                    fontSize="xl"
                    px={6}
                    fontWeight="normal"
                    _hover={{ opacity: 0.8 }}
                  >
                    Play
                  </Button>
                )}
              </Box>
            )}
          </GridItem>

          <GridItem colSpan={{ base: 12, lg: 7 }}>
            {data.sanityHomepage.homepageCategories?.categories?.length > 0 && (
              <Box as="section" mb={24}>
                <CategoryBlock
                  locale={locale}
                  categories={
                    data.sanityHomepage.homepageCategories?.categories
                  }
                  title=""
                />
              </Box>
            )}
          </GridItem>
        </Grid>

        {_rawContent && <PortableText blocks={_rawContent} locale={locale} />}
      </Container>

      <Box mb={16}>
        <LatestNews locale={locale} />
      </Box>

      {data.sanityHomepage.videoEmbed?.embedId && (
        <Container mb={16}>
          <Player
            provider="youtube"
            srcId={data.sanityHomepage.videoEmbed.embedId}
          />
        </Container>
      )}
    </Layout>
  )
}

export const query = graphql`
  query HomepageQuery {
    sanityHomepage {
      homepageTitle {
        en
        fr
        es
        it
        de
        nl
        ko
        ja
      }

      homepageCategories {
        categories {
          to
          image {
            _rawAsset
          }
          title {
            en
            fr
            es
            it
            de
            nl
            ko
            ja
          }
        }
      }
      homepageVideoCta {
        videoCtaTitle {
          en
          fr
          es
          it
          de
          nl
          ko
          ja
        }
        videoCtaYouTubeUrl
      }

      duration
      _rawContent(resolveReferences: { maxDepth: 5 })
      featureImage {
        _rawAsset
      }
      seo {
        keywords
        description
      }
      videoSlides {
        _key
        title {
          en
          fr
          es
          it
          de
          nl
          ko
          ja
        }
        subTitle {
          en
          fr
          es
          it
          de
          nl
          ko
          ja
        }
        featureImage {
          asset {
            gatsbyImageData
          }
        }
      }
      videoEmbed {
        embedId
      }
    }
  }
`

export default HomeTemplate
