import * as React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { FaTwitter, FaLinkedin } from "react-icons/fa"
import {
  Box,
  Button,
  Flex,
  Icon,
  SimpleGrid,
  Text,
  Link,
} from "@chakra-ui/react"
import langKeys from "../../locales/lang.json"
import { useLatestNews } from "../../hooks/get-latest-news"
import { Container } from "../Container"
import { Heading } from "../Heading"
import { getFluidProps } from "../../lib/sanity"
import { DynamicLink } from "../DynamicLink"
import { useLocale } from "../../context/locale/LocaleProvider"

const truncateString = (str, num = 160) => {
  if (str.length <= num) {
    return str
  }

  return str.slice(0, num) + "..."
}

export const LatestNews = ({ locale = "en" }) => {
  const articles = useLatestNews()
  const { state } = useLocale()
  const stateLocale = state?.locale || locale

  if (articles.length > 0) {
    return (
      <Box backgroundColor="#ececec" py={12}>
        <Container>
          <Heading as="h3" fontSize={26} mb={8} textAlign="center">
            {langKeys["latest-from-micropore"][stateLocale]}
          </Heading>
          <SimpleGrid columns={{ base: 1, lg: 3 }} columnGap={6}>
            {articles.map(article => (
              <Box
                bg="white"
                pb={2}
                shadow="lg"
                maxW={{ lg: "sm" }}
                borderWidth="1px"
                overflow="hidden"
                key={article.node.id}
              >
                <Link
                  as={DynamicLink}
                  to={`/about-us/latest-news/${article.node.slug.current}`}
                  style={{ textDecoration: "none" }}
                >
                  {article.node.featureImage?.asset && (
                    <GatsbyImage
                      alt=""
                      image={getFluidProps({
                        id: article.node.featureImage.asset.id,
                      })}
                      style={{ height: 200 }}
                    />
                  )}

                  <Box p="4">
                    <Heading
                      as="h3"
                      fontSize={20}
                      bg="white"
                      mt={article.node.featureImage ? "-58px" : 0}
                      position="relative"
                      padding="16px 16px 10px"
                      mb={0}
                    >
                      {article.node.title[locale] || article.node.title.en}
                    </Heading>

                    <Box px={4}>
                      <Text
                        mb={2}
                        fontWeight="600"
                        color="gray.500"
                        textTransform="capitalize"
                      >
                        {stateLocale === "en"
                          ? article.node.publishedAt
                          : article.node.publishedAtFr}
                      </Text>

                      {article.node.subTitle[locale] ||
                        (article.node.subTitle.en && (
                          <Text mb={5}>
                            {truncateString(
                              article.node.subTitle[locale] ||
                                article.node.subTitle.en
                            )}
                          </Text>
                        ))}

                      <Box mt={4}>
                        <Button bg="blue.100" _hover={{ bg: "brand.100" }}>
                          {langKeys["read-more"][stateLocale]}
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </Link>
              </Box>
            ))}
          </SimpleGrid>
        </Container>
        <Flex
          justifyContent="center"
          color="brand.400"
          fontWeight="bold"
          fontSize={20}
          mt={10}
        >
          <Link as={DynamicLink} to="/about-us/latest-news" isExternal>
            {langKeys["more-news"][stateLocale]}
          </Link>
        </Flex>

        <Container>
          <Flex
            justifyContent="center"
            alignItems={"center"}
            gap={4}
            mt={12}
            as={Link}
            href="https://www.linkedin.com/company/micropore-technologies-ltd"
            target="_blank"
            rel="noreferrer"
            textDecoration="none"
            _hover={{ textDecoration: "none" }}
          >
            <Icon as={FaLinkedin} color="brand.200" fontSize={48} />
            <Heading as="h3" fontSize={26} textAlign="center" mb={0}>
              {langKeys["follow-us"][stateLocale]}
            </Heading>
          </Flex>
        </Container>
      </Box>
    )
  }
}
