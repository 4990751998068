import React, { useEffect, useRef } from "react"
import { Splide, SplideSlide } from "@splidejs/react-splide"
import { Box, Flex } from "@chakra-ui/react"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import { Container } from "../Container"
import { Heading } from "../Heading"

const HeroVideo = ({ slides, duration, locale = "en" }) => {
  const ref = useRef()

  return (
    <Box height={500} position="relative" mb={-24}>
      <Box
        height="100%"
        pos="absolute"
        zIndex={9}
        display="flex"
        alignItems="center"
        justifyContent="flex-start"
        width="100%"
        top="0"
        left="0"
      >
        <Splide
          ref={ref}
          className="hero-video-slide"
          // onMoved={splide => console.log("splide", ref.current)}
          // onMoved={(splide, prev, next) => {
          //   if (typeof window !== "undefined") {
          //     const videos = document.querySelectorAll(".hero-video")
          //     videos.forEach((v, i) => {
          //       if (i === next) {
          //         v.pause()
          //         v.currentTime = 0
          //       }

          //       if (i === prev) {
          //         v.play()
          //       }
          //     })
          //   }
          // }}
          options={{
            perPage: 1,
            perMove: 1,
            arrows: false,
            pagination: false,
            autoplay: true,
            type: "fade",
            rewind: true,
            interval: duration,
            drag: false,
            pauseOnFocus: false,
            isNavigation: false,
            pauseOnHover: false,
            wheel: false,
          }}
        >
          {slides.map((slide, i) => {
            return (
              <SplideSlide key={i}>
                {slide.featureImage && (
                  <GatsbyImage
                    image={slide.featureImage.asset.gatsbyImageData}
                    objectFit="cover"
                    style={{
                      width: "100%",
                      position: "absolute",
                      zIndex: -1,
                      top: 0,
                      left: 0,
                      height: "100%",
                    }}
                  />
                )}

                <Container
                  px={{ base: 4, md: 12 }}
                  height="100%"
                  pos="relative"
                  zIndex={10}
                >
                  <Flex
                    className="slider-box"
                    flexDirection="column"
                    justifyContent="center"
                    maxWidth={650}
                    height="100%"
                    pb={{ base: 8, md: 24, lg: 24 }}
                  >
                    <Heading
                      color="white"
                      fontSize={{ base: 48, md: 60 }}
                      fontWeight={500}
                      as="h2"
                      w="100%"
                      pos="relative"
                      zIndex={10}
                      mb={slide.subTitle ? 8 : 0}
                    >
                      {slide.title[locale] || slide.title.en}
                    </Heading>

                    {slide.subTitle && (
                      <Heading
                        color="white"
                        fontSize={{ base: 24, md: 32 }}
                        as="h3"
                        fontWeight="400"
                        w="100%"
                        pos="relative"
                        zIndex={10}
                      >
                        {slide.subTitle[locale] || slide.subTitle.en}
                      </Heading>
                    )}
                  </Flex>
                </Container>
              </SplideSlide>
            )
          })}
        </Splide>
      </Box>
    </Box>
  )
}

export default HeroVideo
