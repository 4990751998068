import { useStaticQuery, graphql } from "gatsby"

export const useLatestNews = () => {
  const data = useStaticQuery(graphql`
    {
      allSanityArticle(limit: 3, sort: { fields: publishedAt, order: DESC }) {
        edges {
          node {
            id
            publishedAt(formatString: "Do MMMM YYYY")
            publishedAtFr: publishedAt(
              formatString: "Do MMMM YYYY"
              locale: "fr"
            )
            title {
              en
              fr
              es
              it
              de
              nl
              ja
              ko
            }
            subTitle {
              en
              fr
              es
              it
              de
              nl
              ja
              ko
            }
            slug {
              current
            }
            featureImage {
              asset {
                id
              }
            }
          }
        }
      }
    }
  `)

  return data.allSanityArticle.edges || []
}
